@import '../abstracts/_variables.scss';

.context {
    height: 100%;
    color: #061942;
    width: 500px;
    border: 1px solid #D5DDED;
    border-bottom: none;

    & .context-close {
        background: #FFF;
        border: 1px solid $light;
        position: fixed;
        margin-top: 12.5px;
        margin-left: -18px;
        cursor: pointer;
        transition: $transition;

        & svg {
            color: $light;
            transition: $transition;
        }

        &:hover {
            background: #FFF;
            box-shadow: 0 5px 10px rgba(27, 33, 117, 0.14);
            border-color: $gray;

            & svg {
                color: $gray;
            }
        }
    }
}
.context__custom-width {
    width: auto;
}
