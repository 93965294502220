@charset "UTF-8";
html,
body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a:hover {
  cursor: pointer;
}

// 1. Configuration and helpers
@import
  'abstracts/globals',
  'abstracts/variables',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendors/normalize';

// 3. Base stuff
@import
  'base/base',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/layout',
  'layout/sidemenu',
  'layout/context',
  'layout/breadcrumbs',
  'layout/locator';

// 5. Components
@import
  'components/MenuBar/MenuBar',
  'components/CustomBox/CustomBox',
  'components/SearchComponent/SearchComponent',
  'components/Table/TableComponent',
  'components/Table/BasicTableComponent',
  'components/ColumnSelectButton/ColumnSelectButton',
  'components/CustomMenuOptions/CustomMenuOptions',
  'components/FilterDataButtons/FilterDataButtons',
  'components/AccountMenu/AccountMenu',
  'components/ShopifyConnect/ShopifyConnect',
  'components/FlowFilterModal/FlowFilterModal',
  'components/FlowTriggerFilter/FlowTriggerFilter',
  'components/ModalDialog/ModalDialog',
  'components/Editor/Editor',
  'components/EmptyState/EmptyState',
  'components/PageHeader/PageHeader',
  'components/SelectedNavigationList/SelectedNavigationList',
  'components/Alert',
  'components/Charts',
  'components/modal',
  'components/button',
  'components/_loader',
  'components/defaultSpinner';


// 6. Page-specific styles
@import
  'pages/Authentication/CreateOrganizationPage',
  'pages/Audiences/AudiencesListPage',
  'pages/Audiences/SingleAudiencePage',
  'pages/Audiences/SingleCustomerPage',
  'pages/QueryBuilder/QueryBuilder',
  'pages/FilteredCustomers/FilteredCustomers',
  'pages/CsvImport/CSVMappingTable',
  'pages/CsvImport/CSVUploadComponent',
  'pages/CsvImport/CSVSelectAudience',
  'pages/Settings/SettingsPage',
  'pages/Flows/Flows',
  'pages/Campaigns/Campaigns',
  'pages/Statistics/StatisticsPage',
  'pages/Statistics/MessageStatisticsPage';

// 7. Themes
@import
  'themes/themes';
