@import '../../abstracts/variables';

.menu_bar {
    // background-color: #F9FAFD;
    // box-sizing: border-box;
    // border-top: 1px solid #D5DDED;
    // border-bottom: 1px solid #D5DDED;
    margin-top: 25px;
    margin-bottom: 25px;
    max-width: calc(100vw - 46px);
    .menu_bar--buttons {
        max-width: 100%;
        margin: auto;

        button {
            background: $lighter;
            border-bottom: 1px solid transparent;
            text-transform: none;
            font-weight: 400;
            box-sizing: border-box;
            color: $gray;
            border-radius: 25px;
            padding: 5px 14px;
            margin-inline: 5px;
            font-size: 14px;

            &[disabled] {
                color: $light;
            }

            &.selected {
                font-weight: 500;
                background: $light;
                color: $default;
            }
        }
    }
}

.drawer_open {
    .menu_bar {
        margin-top: 25px;
        margin-bottom: 25px;
        max-width: calc(100vw - 300px);
    }
}
