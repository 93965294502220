@import '../abstracts/variables';

header.MuiAppBar-root {
    background: #FFF !important;
    border-bottom: 1px solid #D5DDED;
    box-shadow: none;
    padding: 1rem 2rem;

    & .MuiToolbar-root {
        display: flex;
        justify-content: space-between;
    }
}

div.icon-button {
    border-radius: 50px;
    height: max-content;
    padding: 10px;
    margin-top: 30px;
    border: 1px solid $primary;
    background: #FFF;
    color: $primary;
}

.MuiIconButton-root.icon-button {
    border: 1px solid #D5DDED;
    background: #FFF;
    color: $gray;

    &:hover {
        background: $lighter !important;
        background-color: $lighter !important;
    }
}

.MuiButton-root.label-button {
    border: 1px solid #D5DDED;
    background-color: #FFF;
    color: $gray;
    padding: .7rem 1.7rem;
    text-transform: capitalize;
    min-width: max-content;

    &.active {
        background: $primary;
        color: #FFF;
    }
}

.inverted-menu {
    display: inline-flex;
    border-top: 1px solid #D5DDED;
    border-bottom: 1px solid #D5DDED;
    background: $body-color;
    color:#061942;
    padding: 0 2rem !important;
    text-transform: capitalize;
    width: 100%;
    margin-bottom: 24px;

    & li {
        margin: 0;
        padding: .5rem 1.25rem .5rem .75rem;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        transition: all .15s cubic-bezier(.4, 0, .2, 1) 50ms;
        font-size: 14px;

        &:hover {
            color: $primary;
            border-bottom: 2px solid $primary;

        }
    }
}

.modal {
    transition: opacity 0.3s ease;
    color: $default;
    background: $body-color !important;

    & .modal-dialog {
        background: $body-color !important;

        & .modal-body {
            border-radius: $border-radius !important;
        }

        & .modal-header {
            border-radius: $border-radius $border-radius 0px 0px;
        }

        & .modal-footer {
            border-radius: 0px 0px $border-radius $border-radius;
        }
    }
}

.modal-title.MuiDialogTitle-root {
    padding: 0px;
    margin-right: 3rem;
    flex-grow: 0;
    margin-left: 15px;
    margin-right: 15px;
    color: $default;
    flex-shrink: 0;
    padding: 5px 10px;
    width: max-content;
    overflow: hidden;
    box-sizing: border-box;
    cursor:pointer;
    border-bottom: 2px dashed $light;

    & .MuiTypography-root {
        color: $gray;
        font-weight: 400;
        margin-right: auto;
        padding: 0px;
    }
}

.modal-content {
    min-height: 100%;
    height: auto;
    background: $body-color !important;
    //box-shadow: 0 7px 14px rgba(27, 33, 117, 0.1), 0 3px 6px rgba(0, 0, 0, .08);
    text-transform: none;
    will-change: transform;
    border: 0px;
    //box-shadow: $shadow-op;
    border-radius: $border-radius !important;
    transition: all 0.3s ease;

    & .heading {
        color: #061942;
        font-style: normal;
        font-size: 34px;
        line-height: 36px;
        margin-bottom: 34px;
    }

    & p.description {
        margin-left: auto;
        margin-right: auto;
        max-width: 450px;
        font-size: 14px;
        word-wrap: break-word;
        color: $gray !important;
        margin-bottom: 52px;
    }
}

.MuiDialog-root {
    min-height: 100%;
        height: auto;
    & .MuiDialog-container {
        & .MuiDialog-paper {
            background: $body-color !important;
            & label {
                color: $default;
                &.MuiFormLabel-filled,
                &.MuiFocused {
                    padding: 0px 10px !important;
                    margin-top: -3px !important;
                    background-color: #FFF !important;
                }
            }
        }
    }
}

.modal-stepper {
    margin-inline: auto;
    padding: 0 !important;
    display: inline-flex;
    justify-content: center;
    width: 100%;

    & .MuiButtonBase-root.step-button {
        transition: $transition;

        & .Mui-active,
        &:hover .Mui-active {
            color: $primary !important;
            border-color: $primary !important;
        }

        &:hover {
            & .MuiStepLabel-label {
                color: $darkgray !important;
                border-color: $darkgray !important;

                &.Mui-active,
                &.MuiStepLabel-active {
                    color: $primary !important;
                    border-color: $primary !important;
                }
            }
        }

        & .MuiStepLabel-iconContainer {
            display: none;
        }

        & .MuiStepLabel-labelContainer {
            & .MuiStepLabel-label {
                transition: $transition;
                z-index: 1;
                width: auto;
                height: 50px;
                margin: 0;
                border-bottom: 1px solid $gray;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $gray;
                padding: 10px 20px;
                text-transform: capitalize;
                font-weight: 400;
                font-size: 14px;

                &.MuiStepLabel-active {
                    color: $primary;
                    border-color: $primary;
                }

                &.Mui-disabled {
                    color: #8291AE;
                    border-color: transparent;
                }
            }



            & .MuiSvgIcon-root {
                margin-right: 10px;
            }
        }
    }

    & .MuiStepConnector-root {
        display: none;
    }
}
