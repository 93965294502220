.account_menu {
        text-transform: none !important;
        font-weight: 400 !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        width: 100%;
        color: #EFF0F3;
    .account_menu--content {
        display: flex;
        width: 100%;
        span {
            font-size: 1rem;
            padding-left: 30px;
        }
    }
}