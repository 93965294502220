@import '../../abstracts/variables';

.flow-header {
  flex: 0 1 0 100%;
  background: #FFF;
  border-bottom: 1px solid #D5DDED;
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 50;
  min-height: 75px;

  & .journey {
    width: 450px;
    margin-left: 10px;
    flex-shrink: 1;

    & .flow-header__title {
      flex-grow: 0;
      margin-left: 100px;
      margin-right: 15px;
      color: $default;
      flex-shrink: 0;
      padding: 0px 8px;
      width: max-content;
      overflow: hidden;
      box-sizing: border-box;
      cursor:pointer;
      border-bottom: 2px dashed $light;
      font-size: 18px;

      & .MuiInputBase-root,
      & input {
        width: max-content;
        min-width: 250;
        padding: 2px 0px !important;
        border-bottom: 2px dashed transparent;
        color: $default;
      }
    }

    & .flow-header__status {
      background: $secondary-light;
      color: $secondary;
      font-size: 10px;
      flex-shrink: 0;
      padding: 2px 4px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  & .flow-settings {
    flex-shrink: 1;
    display: inline-flex;

    & .MuiButton-label {
      width: max-content;
      text-transform: capitalize;
    }

    & .flow-action {
      flex-shrink: 1;
      display: inline-flex;
      max-width: 200px;
      height: 65px;
      overflow: hidden;
      flex-wrap: nowrap !important;
      border: 1px solid $light;
      border-radius: 20px;
      color: $primary;
      padding: 10px;
      justify-content: flex-start;
      margin-right: 10px;
      margin-left: 10px;
      text-transform: capitalize;

      & .MuiSvgIcon-root {
        font-size: 28px;
      }

      & .flow-action__step {
        font-weight: 400;
        text-transform: uppercase;
      }

      & .flow-action__trigger {
        flex-shrink: 1;
        display: block;
        width: max-content;
        font-weight: 500;
        color: $default;
        text-transform: capitalize;
      }
    }
  }

  & .flow-options {
    flex-shrink: 0;

    & .publish-menu {
      color: $default-light;
      background: #FFF;
      box-shadow: $shadow-op;

      & .MuiDivider-root {
        background-color: #F8F8FB;
      }

      & .MuiListItemText-root {
        font-size: 12px;
      }

      & .MuiListItemIcon-root {
        min-width: max-content;
        margin-right: 10px;
      }
    }

    & .flow-action {
      box-shadow: none;
      text-transform: capitalize;
      margin-left: 16px;

      &.MuiIconButton-colorPrimary {
        color: $primary;
      }

      &.MuiButton-outlined {
        border-color: $primary-light;
        background-color: $primary-light;
        color: $primary;
      }

      &.MuiButton-contained {
        background-color: $primary;
        color: #FFF;
      }


      & .MuiSvgIcon-root {
        font-size: 24px;
      }

      & .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
        text-transform: capitalize;
        border: 0 !important;
        padding-right: 8px;
      }
      & .MuiButtonGroup-groupedContainedHorizontal:last-child {
        padding: 4px 6px;
        padding-left: 8px;
      }
    }
  }
}

.journey-subheader {
  flex: 0 1 0 100%;
  background-color: rgba(255, 255, 255, .01);
  border-bottom: 1px solid #D5DDED;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  top: 75px;
  position: fixed;
  z-index: 50;
  right: 0;
  height: 50px;
  backdrop-filter: blur(5px);
  padding-left: 105px;

  & .MuiTab-wrapper {
    flex-direction: row;
  }

  & button {
    padding: 7px;
    color: $gray;
    transition: all .4s cubic-bezier(.4, 0, .2, 1) 100ms;

    & svg {
      font-size: 26px;
    }

    &:hover {
      filter: drop-shadow(0px 1px 2px rgba($gray, .5));
    }

    // &.active {
    //   background: rgba(130, 145, 174, 0.1);
    // }
  }
}
