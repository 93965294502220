@import '../abstracts/variables';

.MuiButton-root {
  &.disabled-button {
    border: 1px solid #D5DDED;
    background: #F9FAFD;
    color:#061942;
    padding: .7rem 1.7rem;
    text-transform: capitalize;

    width: 100%;
    margin-bottom: 24px;

    &:hover {
        background: $primary;
        color: #FFF;
        cursor: pointer;
    }
  }

  &.primary-button {
    border: 1px solid $primary;
    background: $primary;
    color: #FFF;
    padding: .7rem 1.7rem;
    text-transform: capitalize;

    width: 100%;

    &:hover {
      background: $primary;
      color: #FFF;
      cursor: pointer;
    }
  }

  &.primary-button-light {
    border: 1px solid $primary-light;
    background: $primary-light;
    color: $primary;
    text-transform: capitalize;

    width: 100%;

    &:hover {
      background: $primary-light;
      color: $warning;
      cursor: pointer;
    }
  }

  &.secondary-button {
    border: 1px solid $secondary;
    background: $secondary;
    color: #FFF;
    text-transform: capitalize;

    width: 100%;

    &:hover {
      background: $secondary;
      color: #FFF;
      cursor: pointer;
    }
  }

  &.secondary-button-light {
    border: 1px solid $secondary-light;
    background: $secondary-light;
    color: $secondary;
    text-transform: capitalize;

    width: 100%;

    &:hover {
      background: $secondary-light;
      color: $warning;
      cursor: pointer;
    }
  }

  &.warning-button {
    border: 1px solid $warning;
    background: $warning;
    color: #FFF;
    text-transform: capitalize;
    width: 100%;

    &:hover {
      background: $warning;
      color: #FFF;
      cursor: pointer;
    }
  }

  &.warning-button-light {
    padding: 10px;
    border: 1px solid $warning-light;
    background: $warning-light;
    color: $warning;
    text-transform: capitalize;

    width: 100%;

    &:hover {
      background: $warning-light;
      color: $warning;
      cursor: pointer;
    }
  }

  &.success-button-light {
    padding: 10px;
    border: 1px solid $success-light;
    background: $success-light;
    color: $success;
    text-transform: capitalize;

    width: 100%;

    &:hover {
      background: $success-light;
      color: $success;
      cursor: pointer;
    }
  }
}
